<template>
  <div id="app">
    <v-app-bar
      color="deep-purple accent-4"
      dense
      dark

      style="background:#003F74!important"
    >

      <v-toolbar-title @click="$router.push('/')"><img :src="logo" style="width:30px;height:30px;margin-top:-5px"> KES LAKAY</v-toolbar-title>

      <v-spacer></v-spacer>
<v-btn v-b-modal.modal-1
      style="background:transparent;box-shadow:none!important;min-width:0px!important"
    >
      <v-icon>
        mdi-filter-outline
      </v-icon>
    </v-btn>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <div>

  <b-modal id="modal-1" title="Filtres" @ok="filter">
    <div class="row">
      <div class="col-md-12">
        <label>Début Période</label>
        <input v-model="debut_periode" type='date'  placeholder="De" class='form-control'>
      </div>
      <div class="col-md-12">
        <label>Fin de Période</label>
        <input v-model="fin_periode" type='date'  placeholder="A" class='form-control'>
      </div>
      <div class="col-md-12">
        <label>Devise</label>
        <select class="form-control" v-model=rate_id>
          <option value="1">HTG</option>
          <option value="2">USD</option>
        </select>
      </div>
    </div>
  </b-modal>
</div>
    
      <v-main>
        <v-sheet
    class="overflow-hidden appbarsheet"
    style="position: relative;"
  >

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="sidebar_user_name">{{ getAuthenticatedUser() }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
      <v-list-group
        v-for="item in listItems"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          link :to="child.link"
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        
      </v-list-group>
      <v-list-item
          key="Utilisateurs"
          href="/users"
        >
          <v-list-item-icon>
            <v-icon style="color:#F68D3A!important">mdi-account-circle-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Utilisateurs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      <v-list-item
          key="Log Out"
          v-on:click="logout"
        >
          <v-list-item-icon v-if=!loading>
            <v-icon style="color:red!important">mdi-power-standby</v-icon>
          </v-list-item-icon>

          <v-progress-circular v-if=loading
      indeterminate
      color="red"
    ></v-progress-circular>

          <v-list-item-content>
            <v-list-item-title>Déconnexion</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

    </v-list>
    </v-navigation-drawer>
      
        <router-view></router-view>
        </v-sheet>
      </v-main>


  </div>
</template>
<script type="text/javascript">
  import moment from 'moment'
  import logo from "/src/assets/img/white_emblem.png"
  // const host = window.location.host;
  // const parts = host.split('.');

  export default {
  data () {
      return {
        drawer: null,
        rate_id: 1,
        debut_periode: moment().format('YYYY-MM-DD'),
        fin_periode: moment().format('YYYY-MM-DD'),
        filterdialog: false,
        loading: false,
        logo:logo,
        rates: [{"id" : 1, 'label' : 'HTG'}, {"id" : 2, 'label' : 'USD'}, {"id" : 3, 'label' : 'LES DEUX'}],
        listItems: [
        {
          action: 'mdi-chart-box-outline',
          active: false,
          items: [
            { title: 'Ventes', link: '/sales' },
            // { title: 'Catégories', link: '/reports/categories' },
            { title: 'Produits', link: '/productsreport' },
            { title: 'Agents', link: '/agents' },
            { title: 'Caisse', link: '/closing' },
            { title: 'Stock', link: '/stock' },
          ],
          title: 'Rapports',
        },
        {
          action: 'mdi-shopping-outline',
          active: false,
          items: [
            { title: 'Catégories', link: '/categories' },
            { title: 'Produits', link: '/products' },
            { title: 'Mises à Jours', link: '/catalog' },
            { title: 'Opérations', link: '/operations' },
          ],
          title: 'Catalogue',
        },
        {
          action: 'mdi-account-circle-outline',
          active: false,
          items: [
            { title: 'Liste', link: '/customers' },
          ],
          title: 'Clients',
        },
        {
          action: 'mdi-cog-outline',
          active: false,
          items: [
            { title: 'Profil', link: '/account' },
            { title: 'Magasin', link: '/stores' },
            { title: 'Appareils', link: '/subscriptions' },
            { title: 'Renouvellements', link: '/renewals' },
            { title: 'Taux du Jour', link: '/rates' },
          ],
          title: 'Compte',
        },
      ],
      }
    },
    methods: {
      logout: async function(){
        this.loading = true
        localStorage.setItem("debut_periode", '')
        localStorage.setItem("fin_periode", '')
        await this.$store.dispatch('LogOut')
        this.$router.push('/login')
      },
      setFilters: function(){
        var debut_periode = moment().format('YYYY-MM-DD')
        var fin_periode = moment().format('YYYY-MM-DD')
        var filter_rate_id = 1

        if(debut_periode == '' || debut_periode == 'undefined'){
          this.debut_periode = moment().format('YYYY-MM-DD')
        }else{
          this.debut_periode = debut_periode
        }

        if(fin_periode == '' || fin_periode == 'undefined'){
          this.fin_periode = moment().format('YYYY-MM-DD')
        }else{
          this.fin_periode = fin_periode
        }

        if(filter_rate_id == '' || filter_rate_id == 'undefined'){
          this.rate_id = 1
        }else{
          this.rate_id = filter_rate_id
        }

        localStorage.setItem("filter_rate_id", this.rate_id)
        localStorage.setItem("debut_periode", this.debut_periode)
        localStorage.setItem("fin_periode", this.fin_periode)
      },
      filter: function(){
        localStorage.setItem("filter_rate_id", this.rate_id)
        localStorage.setItem("debut_periode", this.debut_periode)
        localStorage.setItem("fin_periode", this.fin_periode)

        this.$router.go()
      },
      getAuthenticatedUser(){
        var authentication = JSON.parse(localStorage.authentication)
        return authentication.user.full_name
      },
      getSchema(){
        var authentication = JSON.parse(localStorage.authentication)
        return authentication.schema
      },
      async getBusiness(){
          let promise = this.$axios.get('/businesses?prefix='+this.getSchema())
          let result = await promise
          if(result != undefined){
            localStorage.setItem("business", JSON.stringify(result.data[0]))
          }else{
            await this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
          
      }
    },
    mounted(){
    this.getBusiness()
    this.setFilters()
  },
};
</script>
<style lang="scss">
.sidebar_user_name{
  margin-top:15px;
  text-align:center;
  font-weight:600!important;
  margin-right:10px;
}
.theme--dark.v-app-bar.v-toolbar.v-sheet {
    background-color: #1867c0!important;
}
#modal-1___BV_modal_footer_ button{
  color:white;
}

#modal-1___BV_modal_footer_ button.btn-primary{
  background:#1867c0 !important;
}
.v-toolbar__title{
  cursor:pointer;
}
.v-toolbar__content, .v-toolbar__extension{
  padding:4px 0px!important;
}
.v-list-item__title{
  font-size:15px;
  margin-left:10px;
  font-weight:400;
}

.v-list-item .v-progress-circular{
  color:red!important;
  width:25px!important;
  height:25px!important;
  margin-top:0px!important;
}

.v-list-item .v-progress-circular .v-progress-circular__overlay{
  color:red!important;
}
.appbarsheet{
  min-height: 100vh;
}

.v-breadcrumbs__item{
  color:black;
}
.v-breadcrumbs {
  background:#f3f3f3;
  border-bottom:1px solid #f2f2f2;
  padding: 10px 12px!important;
}
.container-xl, .container-lg, .container-md, .container-sm, .container{
  max-width:3000px!important;
}
.v-toolbar__title{
  padding-left:10px!important;
}

.v-application .text-h5{
  font-size: 20px!important;
}
.v-btn:not(.v-btn--round).v-size--default{
  min-width:94px!important;
}
.v-list-item .v-list-item__title:hover, .v-list-item .v-list-item__subtitle:hover, a:hover{
  font-style:none!important;
  text-decoration:none!important;
}
.v-main__wrap > .theme--light{
  background:#ffffff!important;
}
.v-navigation-drawer__content .v-list-group__header__prepend-icon .mdi:before{
  color:#F68D3A!important;
}
</style>
